import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Layout, Card } from 'antd';

import StatusTable from './StatusTable';
import SettingsForm from './SettingsForm';
import FacilityOverview from './FacilityOverviewPage';
import FacilityDetails from './FacilityDetailsPage';
import Engrain from '../../src/features/engrain/Engrain';
import DataWarehouseDetails from './DataWarehouseDisplay';
import AmSurveyDisplay from './AmSurveyDisplay';

import StoreAudit from './StoreAuditDisplay';

const { TabPane } = Tabs;

const FacilityTabs = (facility) => {
  const selectedFacility = useSelector((state) => state.facilities.selectedFacility);
  const roles = useSelector((state) => state.auth.userRoles);

  // Function to check if engrain properties are present and not empty
  const hasValidEngrainData = () => {
    return (
      selectedFacility &&
      selectedFacility.engrain &&
      selectedFacility.engrain.h != null &&
      selectedFacility.engrain.d != null &&
      selectedFacility.engrain.asset_id != null
    );
  };

  // Handle tab change
  const handleTabChange = (key) => {
    if (key === '2') {
      // Trigger geolocation logic for the "Property Survey" tab
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            };
            // Store location in localStorage
            localStorage.setItem('userLocation', JSON.stringify(location));
            console.log('Location saved:', location);
          },
          (error) => {
            console.warn('Unable to retrieve location. Location services are disabled or an error occurred.', error);
          }
        );
      } else {
        console.warn('Geolocation is not supported by this browser.');
      }
    }
  };

  return (
    <Card style={{ background: '#F5F5F5' }}>
      <Tabs defaultActiveKey="1" type="card" onChange={handleTabChange}>
        <TabPane tab="Facility Overview" key="1">
          <FacilityOverview />
        </TabPane>

        <TabPane tab="Property Survey" key="2">
          <StoreAudit facilityId={facility.id} />
        </TabPane>

        <TabPane tab="Custom Fields" key="3">
          <FacilityDetails />
        </TabPane>

        {hasValidEngrainData(selectedFacility) && (
          <TabPane tab="Store Map" key="4">
            <Engrain />
          </TabPane>
        )}

        <TabPane tab="Status Page" key="5">
          <StatusTable facilityId={facility.id} />
        </TabPane>

        {!roles.includes('PropertyManager') && !roles.includes('AreaManager') && (
          <TabPane tab="Settings Page" key="6">
            <SettingsForm facilityId={facility.id} />
          </TabPane>
        )}

        {JSON.stringify(roles).includes('admin') && (
          <TabPane tab="Data Warehouse" key="7">
            <DataWarehouseDetails facilityId={facility.id} />
          </TabPane>
        )}
{/* 
        {!roles.includes('PropertyManager') && (
          <TabPane tab="AM Store Audit" key="8">
            <AmSurveyDisplay facilityId={facility.id} />
          </TabPane>
        )} */}


      </Tabs>
    </Card>
  );
};

export default FacilityTabs;

import React, { useState } from 'react';
import { Descriptions, Divider, Tooltip, Button, Drawer } from 'antd';
import './facilityDisplayStyles.css';
import EditCustomFacilityDetails from "./EditCustomFacilityDetails";
import { useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';

const DisplayFacilityDetailsObject = () => {
  const custom_fields = useSelector((state) => state.facilities.selectedFacility.custom);

  // State to manage Drawer visibility
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  // Functions to open and close the Drawer
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  return (
    <div className="facility-details-container">
      {/* Header Section with Title and Edit Button */}
      {/* <div className="facility-details-header">
        <h2>Custom Facility Details</h2>
        <Button type="primary" icon={<EditOutlined />} onClick={showDrawer}>
          Edit Custom Details
        </Button>
      </div> */}

      {/* Drawer for Editing Custom Facility Details */}
    
        <EditCustomFacilityDetails onClose={closeDrawer} />
      

      {/* Facility Details Section */}
      {custom_fields ? (
        <>
          {/* Area/Region/Division Details */}
          <Divider orientation="left">Area/Region/Division Details</Divider>
          <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }} layout="vertical">
            <Descriptions.Item label="Area Number">
              {custom_fields.areaNumber || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Region Number">
              {custom_fields.regionNumber || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Division Number">
              {custom_fields.divisionNumber || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Division Geography">
              {custom_fields.divisionGeography || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Davinci Lock">
              {custom_fields.davinciLock || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="College Market">
              {custom_fields.collegeMarket === true
                ? 'Yes'
                : custom_fields.collegeMarket === false
                ? 'No'
                : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Birdeye Sub Business Id">
              {custom_fields.birdeye_sub_business_id || 'N/A'}
            </Descriptions.Item>
          </Descriptions>

          {/* Area Manager (AM) Details */}
          <Divider orientation="left">Area Manager (AM) Details</Divider>
          <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }} layout="vertical">
            <Descriptions.Item label="Area Manager">
              {custom_fields.areaManager || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Area Manager Email">
              {custom_fields.areaManagerEmail ? (
                <a href={`mailto:${custom_fields.areaManagerEmail}`}>
                  {custom_fields.areaManagerEmail}
                </a>
              ) : (
                'N/A'
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Area Manager Phone Number">
              {custom_fields.areaManagerPhoneNumber || 'N/A'}
            </Descriptions.Item>
          </Descriptions>

          {/* Regional Manager (RM) Details */}
          <Divider orientation="left">Regional Manager (RM) Details</Divider>
          <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }} layout="vertical">
            <Descriptions.Item label="Regional Manager">
              {custom_fields.regionalManager || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Regional Manager Email">
              {custom_fields.regionalManagerEmail ? (
                <a href={`mailto:${custom_fields.regionalManagerEmail}`}>
                  {custom_fields.regionalManagerEmail}
                </a>
              ) : (
                'N/A'
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Regional Manager Phone Number">
              {custom_fields.regionalManagerPhoneNumber || 'N/A'}
            </Descriptions.Item>
          </Descriptions>

             {/* City Manager (CM) Details */}
             <Divider orientation="left">City Manager Details</Divider>
          <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }} layout="vertical">
            <Descriptions.Item label="City Manager">
              {custom_fields.cityManager || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="City Manager Email">
              {custom_fields.cityManager ? (
                <a href={`mailto:${custom_fields.cityManager}`}>
                  {custom_fields.cityManager}
                </a>
              ) : (
                'N/A'
              )}
            </Descriptions.Item>
            <Descriptions.Item label="City Manager Phone Number">
              {custom_fields.cityManager || 'N/A'}
            </Descriptions.Item>
          </Descriptions>

          {/* Director Details */}
          <Divider orientation="left">Director Details</Divider>
          <Descriptions bordered column={1} layout="vertical">
            <Descriptions.Item label="Division Director">
              {custom_fields.division_director || 'N/A'}
            </Descriptions.Item>
          </Descriptions>

          {/* Other Custom Details */}
          <Divider orientation="left">Other Custom Details</Divider>
          <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }} layout="vertical">
            <Descriptions.Item label="Pro">
              {custom_fields.pro || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Annex Site">
              {custom_fields.annexSite || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Landmarks">
              {custom_fields.landmarks || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="RM Analyst">
              {custom_fields.rmAnalyst || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Gate System">
              {custom_fields.gateSystem || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Company Code">
              {custom_fields.companyCode || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Demographics">
              {custom_fields.demographics || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="On Site Manager">
              {custom_fields.oNSiteManager || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="NSA Store Number">
              {custom_fields.nsaStoreNumber || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Annex Site Number">
              {custom_fields.annexSiteNumber || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Apartment On Site">
              {custom_fields.apartmentOnSite || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Gate System Notes">
              {custom_fields.gateSystemNotes || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Boat/RV/Semi-Truck/Wine/Other">
              {custom_fields.boatRVSemiTruckWineOther || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Property Management System">
              {custom_fields.propertyManagementSystem || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Special Hours For Business Tenants/Other">
              {custom_fields.specialHoursForBusinessTenantsOther || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Anything Unique About This Facility Or Its Offerings">
              {custom_fields.anythingUniqueAboutThisFacilityOrItsOfferings ? (
                <Tooltip title={custom_fields.anythingUniqueAboutThisFacilityOrItsOfferings}>
                  <span>
                    {custom_fields.anythingUniqueAboutThisFacilityOrItsOfferings.length > 50
                      ? `${custom_fields.anythingUniqueAboutThisFacilityOrItsOfferings.substring(0, 50)}...`
                      : custom_fields.anythingUniqueAboutThisFacilityOrItsOfferings}
                  </span>
                </Tooltip>
              ) : (
                'N/A'
              )}
            </Descriptions.Item>
          </Descriptions>
        </>
      ) : (
        <div className="facility-error-message">
          There seems to be a problem loading the facility details, please refresh and try again.
        </div>
      )}
    </div>
  );
};

export default DisplayFacilityDetailsObject;
